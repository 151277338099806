import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Image } from 'antd';
import { AppDispatch, RootState } from '../../Store';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Lottie/Loading';
import { getSingleFeedback } from '../../Redux/Action/feedback';

const FeedBackDetailPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data, loading } = useSelector((state: RootState) => state.feedback);
    const { id } = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            dispatch(getSingleFeedback({ id }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                Email: data?.UserFeedbacks?.Email,
                Name: data?.UserFeedbacks?.Name,
                Feedback: data?.Description,
            })
        }
    }, [data])


    return (
        <div className='container-fluid servicess userdetailll'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Feedback Detail</h2>
                </div>
                {
                    loading ?
                        <div><Loading /></div>
                    :
                    <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                        <Form layout='vertical' form={form} initialValues={data}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="User Name"
                                        name="Name"
                                    >
                                        <Input readOnly placeholder='Name' />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Email"
                                        name="Email"
                                    >
                                        <Input readOnly placeholder='Email' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12">
                                    <Form.Item
                                        label="Feedback"
                                        name="Feedback"
                                    >
                                        <Input.TextArea rows={5} readOnly placeholder='Feedback' />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                }
            </div>
        </div>
    );
};

export default FeedBackDetailPage;
