import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Checkbox, Form, GetProp, Image, Input, Select, Upload, UploadFile, UploadProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { getSingleQuote } from '../../Redux/Action/quote';
import Loading from '../../Lottie/Loading';
import StatusForm from './StatusForm';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const { Option } = Select;

const DetailPage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [isVideo, setIsVideo] = useState(false);
    const [ids, setId] = useState<string>();
    const [status, setStatus] = useState<string>();

    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => {
        navigate(-1)
        setShowModal(false)};



    const { quoteData, loading } = useSelector((state: RootState) => state.quote);
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            dispatch(getSingleQuote({ id }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (quoteData && quoteData.QuoteRequestAssets) {
            const updatedFileList: UploadFile[] = quoteData.QuoteRequestAssets.map((simage: { _id: string, Image?: string, Video?: string }) => {
                const fileUrl = simage.Image || simage.Video;
                return {
                    uid: simage._id,
                    status: 'done',
                    url: fileUrl,
                    name: simage?.Image ? '' : 'video.mp4',
                    // thumbUrl: fileUrl,
                    type: simage.Video ? 'video/mp4' : 'image/jpeg',
                };
            })// Filter out files without a valid URL
    
            setFileList(updatedFileList);
        } else {
            setFileList([]);
        }
    }, [quoteData]);

    const handleChange = (id: string, value: string) => {
        setStatus(value);
        setId(id);
        handleModalOpen();
    };

    useEffect(() => {
        if (quoteData) {
            form.setFieldsValue({
                Name: quoteData?.UserRequestQuotes?.Name,
                Email: quoteData?.UserRequestQuotes?.Email,
                Phone: quoteData?.UserRequestQuotes?.Phone,
                Location: quoteData?.Location,
                Address: quoteData?.Address,
                Address2: quoteData?.Address2,
                State: quoteData?.State,
                City: quoteData?.City,
                PostalCode: quoteData?.PostalCode,
                Message: quoteData?.Message,
                ContactReason: quoteData?.ContactReason,
                CustomerService: quoteData?.CustomerService,
                ContactTime: quoteData?.ContactTime,
                Reason: quoteData?.QuoteRequestDetail?.Reason,
                BasePrice:  "$" + quoteData?.Quotes?.BasePrice ,
                AdditionalFees:  "$" +  quoteData?.Quotes?.AdditionalFees ,
                DiscountApplied:  "$" +  quoteData?.Quotes?.DiscountApplied ,
                Tax:    quoteData?.Quotes?.Tax + "%",
                TotalPrice: "$" +  quoteData?.Quotes?.TotalPrice,
            });
        }
    }, [quoteData]);

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src && file.originFileObj) {
            src = await new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as FileType);
                reader.onload = () => resolve(reader.result as string);
            });
        }

        setPreviewUrl(src);
        setIsVideo(file.type?.includes('video') || false);
        setPreviewVisible(true);
    };

    const handlePreviewClose = () => {
        setPreviewVisible(false);
        setPreviewUrl(null);
    };

    if (!quoteData) {
        return <div>Quote not found</div>;
    }

    return (
        <div className='container-fluid servicess'>
            {loading ? (
                <div>
                    <Loading />
                </div>
            ) : (
                <div className="row">
                    <div className="col-sm-12 addnew" style={{ marginBottom: '20px' }}>
                        <h2>Quote Detail</h2>
                        {/* <Button
                            style={{ textTransform: 'capitalize' }}
                            className={
                                quoteData?.status === "pending" ? 'orange' :
                                quoteData?.status === "rejected" ? 'red' :
                                'green'
                            }
                        >
                            {quoteData?.status}
                        </Button> */}








{quoteData?.status == 'pending' ?
                                                    <td>
                                                        <Select
                                                            defaultValue={quoteData.status}
                                                            onChange={(value) => handleChange(quoteData._id, value)}
                                                            style={{ width: 120, textTransform: 'capitalize' }}
                                                        >
                                                            <Option value="approved" className="">Approved</Option>
                                                            <Option value="rejected" className="">Rejected</Option>
                                                        </Select>
                                                    </td>
                                                    :
                                                    quoteData?.status == 'approved' ?
                                                        <td className='green' style={{ textTransform: 'capitalize' }}><div></div>{quoteData?.status}</td>
                                                        :
                                                        <td className='red' style={{ textTransform: 'capitalize' }}><div></div>{quoteData?.status}</td>
                                                }


                    </div>

                    <div className="col-sm-12">
                        <Form
                            initialValues={quoteData}
                            layout='vertical'
                            form={form}
                        >
                            <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="Name"
                                            label="Name"
                                        >
                                            <Input placeholder='Name' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="Email"
                                            label="Email"
                                        >
                                            <Input placeholder='Email' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="Phone"
                                            label="Phone Number"
                                        >
                                            <Input placeholder='Phone' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="Location"
                                            label="Location"
                                        >
                                            <Input placeholder='Location' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="Address"
                                            label="Address"
                                        >
                                            <Input placeholder='Address' readOnly />
                                        </Form.Item>
                                    </div>

                                    {/* <div className="col-sm-6">
                                        <Form.Item
                                            name="Address2"
                                            label="Address Line 2"
                                        >
                                            <Input placeholder='Address Line 2' readOnly />
                                        </Form.Item>
                                    </div> */}


                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="State"
                                            label="State"
                                        >
                                            <Input placeholder='State' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="City"
                                            label="City"
                                        >
                                            <Input placeholder='City' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="PostalCode"
                                            label="Postal Code"
                                        >
                                            <Input placeholder='Postal Code' readOnly />
                                        </Form.Item>
                                    </div>



                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="ContactReason"
                                            label="Reason for contact?"
                                        >
                                            <Input placeholder='Reason for contact?' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="ContactTime"
                                            label="When can we contact you?"
                                        >
                                            <Input placeholder='When can we contact you?' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="Message"
                                            label="Message"
                                        >
                                            <Input.TextArea rows={5} placeholder='Your Message' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            name="CustomerService"
                                            label="Other Custom Services"
                                        >
                                            <Input.TextArea rows={5} placeholder='Other Custom Services' readOnly />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-3">
                                        <Form.Item
                                            name="TextConsent"
                                            label="Receive future text"
                                        >
                                            <Checkbox checked={quoteData?.TextConsent}>
                                                I agree to future texts.
                                            </Checkbox>
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-3" style={{
                                        paddingBottom: '50px'
                                    }}>
                                        <Form.Item
                                            name="EmailConsent"
                                            label="Receive future emails"
                                        >
                                            <Checkbox checked={quoteData?.EmailConsent}>
                                                I agree to future emails.
                                            </Checkbox>
                                        </Form.Item>
                                    </div>

                                    {quoteData?.QuoteRequestAssets?.length > 0 && (
                                    <div className="col-sm-6">
                                        <Upload
                                            action="/upload.do"
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={onPreview}
                                            disabled // Disable the entire Upload component

                                            onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                                        >
                                            {fileList.length < quoteData?.QuoteRequestAssets?.length && '+ Upload'}
                                        </Upload>
                                    </div>

                                )}
                           {
quoteData?.QuoteRequestDetail && (

    <div className='col-sm-12'>
    <Form.Item
                name="Reason"
                label="Reason For Rejection of Quote Request"
            >
    <Input.TextArea rows={5} placeholder='Reason For Rejection' readOnly />
    </Form.Item>
    </div>
)
                            
                           }
                            </div>
{
quoteData.Quotes && (


<div className='row'>

<div className='col-sm-4'>
    <Form.Item
                name="BasePrice"
                label="Base Price"
            >
    <Input  placeholder='Base Price' readOnly />
    </Form.Item>
    </div>
<div className='col-sm-4'>
    <Form.Item
                name="AdditionalFees"
                label="Additional Fees"
            >
    <Input  placeholder='Additional Fees' readOnly />
    </Form.Item>
    </div>
<div className='col-sm-4'>
    <Form.Item
                name="DiscountApplied"
                label="Discount Applied"
            >
    <Input  placeholder='Discount Applied' readOnly />
    </Form.Item>
    </div>
<div className='col-sm-6'>
    <Form.Item
                name="Tax"
                label="Tax"
            >
    <Input  placeholder='Tax' readOnly />
    </Form.Item>
    </div>
<div className='col-sm-6'>
    <Form.Item
                name="TotalPrice"
                label="Total Price"
            > 
    <Input  placeholder='Total Price' readOnly />
    </Form.Item>
    </div>








</div>



)


}



                        </Form>
                    </div>
                </div>
            )}

            <Modal
                show={previewVisible}
                onHide={handlePreviewClose}
                centered
                // size="md"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {isVideo ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={previewUrl || ''} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img alt="Preview" style={{ width: '100%' }} src={previewUrl || ''} />
                    )}
                </Modal.Body>
            </Modal>
      
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Quote Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StatusForm id={ids} handleClose={handleModalClose} status={status} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DetailPage;