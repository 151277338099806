import { CChart } from '@coreui/react-chartjs'
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Spin } from 'antd';
// import Loader from "../Loader/Loader";
import { AppDispatch, RootState } from '../../Store';
import { getDashboardStats } from '../../Redux/Action/dashboard';
import Loading from '../../Lottie/Loading';

const Chart = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { error, data, success, loading } = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        dispatch(getDashboardStats());
    }, [dispatch]);

    const monthNames = data?.data?.map((date: any) => {
        if (date.month) {
            return moment(date.month, "YYYY-MM").format("MMMM");
        } else {
            return date.date;
        }
    });

    const dataCount = data?.data?.map((item: any) => item.count);
    return (
        <Fragment>
            <div className="container-fluid servicess">
                <div className="row">
                    <div className="col-sm-12 addnew">
                        <h2>Dashboard</h2>
                    </div>
                    {
                        loading ?
                            <div>
                                <Loading />
                            </div>
                            :
                            <>
                                <div className="col-sm-12 barchart">
                                    <CChart
                                        type="bar"
                                        data={{
                                            labels: monthNames,
                                            datasets: [
                                                {
                                                    label: "Quote Requests",
                                                    backgroundColor: "#203552",
                                                    data: dataCount,
                                                },
                                            ],
                                        }}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    labels: {
                                                        color: "#000",
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    grid: {
                                                        color: "#ffffff1a",
                                                    },
                                                    ticks: {
                                                        color: "#000",
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                        color: "#ffffff1a",
                                                    },
                                                    ticks: {
                                                        color: "#000",
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    <div className="row dashStats">
                                        <div className="col-sm-3">
                                            <h3>Registered Users</h3>
                                            <p>
                                                {data?.usersCount}
                                            </p>
                                        </div>
                                        <div className="col-sm-3">
                                            <h3>Approved Requests</h3>
                                            <p>
                                                {data?.approvedCount}
                                            </p>
                                        </div>
                                        <div className="col-sm-3">
                                            <h3>Pending Requests</h3>
                                            <p>
                                                {data?.pendingCount}
                                            </p>
                                        </div>
                                        <div className="col-sm-3">
                                            <h3>Rejected Requests</h3>
                                            <p>
                                                {data?.rejectedCount}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </div>
        </Fragment>
    );
};

export default Chart