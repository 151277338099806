import { createAsyncThunk } from "@reduxjs/toolkit";
import {

  changeStatusStart,
  changeStatusSuccess,
  changeStatusFailure,

} from "../Reducer/changeStatusReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { ChangeStatusResponse } from "../../interfaces";
import { getQuotesListing } from "./quote";






export const changeQuoteStatus = createAsyncThunk<ChangeStatusResponse, { id: string, values: FormData }, { rejectValue: string }>(
  "quotestatus/change",
  async ({id, values}, thunkAPI) => {
    try {
      thunkAPI.dispatch(changeStatusStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URl}changeStatus/${id}`,
        values,
        config
      );
console.log(response.data.message, "<==========response")
      thunkAPI.dispatch(changeStatusSuccess(response));
      thunkAPI.dispatch(getQuotesListing({ page: 1, pageSize: 10 }));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to change Status!";
      thunkAPI.dispatch(changeStatusFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


