import React from 'react';
import Lottie from 'react-lottie';
import lottieee from './cleaning.json';

const Loading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: lottieee,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Lottie
                options={defaultOptions}
                width={500}
            />
        </div>
    );
};

export default Loading;
