import React from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Page from '../Components/Servicess/Page'

const Services = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-sm-3 sidebar">
                    <Sidebar />
                </div>
                <div className="col-sm-9 noSide">
                    <Page />
                </div>
            </div>
        </div>
    )
}

export default Services
