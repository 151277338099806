import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    quoteListingStart,
    quoteListingSuccess,
    quoteListingFailure,
    singleQuoteStart,
    singleQuoteFailure,
    singleQuoteSuccess,
    deleteQuoteStart,
    deleteQuoteFailure,
    deleteQuoteSuccess,
} from "../Reducer/quotesReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { quoteListing, singleQuoteRequest, PaginationParams } from "../../interfaces";

export const getQuotesListing = createAsyncThunk<quoteListing, PaginationParams>(
    "quote",
    async ({ page, pageSize }, thunkAPI) => {
        try {
            thunkAPI.dispatch(quoteListingStart());

            const token = Cookies.get("token");

            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<quoteListing>(
                `${process.env.REACT_APP_API_URl}requestLisiting`,
                config
            );

            thunkAPI.dispatch(quoteListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch quotes";

            thunkAPI.dispatch(quoteListingFailure(errorMessage));
            throw error;
        }
    }
);


export const getSingleQuote = createAsyncThunk<singleQuoteRequest, { id: string }, { rejectValue: string }>(
    "quote/getSingleQuote",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(singleQuoteStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<singleQuoteRequest>(
                `${process.env.REACT_APP_API_URl}singleRequest/${id}`,
                config
            );

            thunkAPI.dispatch(singleQuoteSuccess(response.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch quote details";
            thunkAPI.dispatch(singleQuoteFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const deleteQuote = createAsyncThunk(
    "quote/deleteQuote ",
    async (id: string, thunkAPI) => {
        try {
            thunkAPI.dispatch(deleteQuoteStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.delete(
                `${process.env.REACT_APP_API_URl}deleteRequest/${id}`, config
            );

            thunkAPI.dispatch(deleteQuoteSuccess(response.data));
            return response.data;
        } catch (error: any) {
            if (error) {
                const errorMessage = error?.response?.data?.message;
                thunkAPI.dispatch(deleteQuoteFailure(errorMessage));
                return thunkAPI.rejectWithValue(errorMessage);
            }
        }
    }
);