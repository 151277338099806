import { createSlice } from "@reduxjs/toolkit";

interface quote {
    loading: boolean;
    editLoading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    quoteData: any;
}

const initialState: quote = {
    loading: false,
    deleteLoading: false,
    editLoading: false,
    error: null,
    success: null,
    deleteError: null,
    deleteSuccess: null,
    quoteData: {},
};

const quoteSlice = createSlice({
    name: "quote",
    initialState,
    reducers: {
        quoteListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        quoteListingSuccess(state, action) {
            state.loading = false;
            state.quoteData = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        quoteListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singleQuoteStart(state) {
            state.loading = true;
            state.error = null;
        },
        singleQuoteSuccess(state, action) {
            state.loading = false;
            state.quoteData = action.payload.data;
            console.log(action.payload.data, '<==============action.payload.data')
            state.success = action.payload.data.message;
        },
        singleQuoteFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteQuoteStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteQuoteSuccess(state, action) {
            state.deleteLoading = false;
            state.quoteData = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteQuoteFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
        },
    },
});

export const {
    quoteListingStart,
    quoteListingSuccess,
    quoteListingFailure,
    singleQuoteStart,
    singleQuoteFailure,
    singleQuoteSuccess,
    deleteQuoteStart,
    deleteQuoteFailure,
    deleteQuoteSuccess,
    clearState,
} = quoteSlice.actions;

export default quoteSlice.reducer;