import { combineReducers } from "redux";
import authAdmin from "./Reducer/authAdminReducer";
import service from "./Reducer/serviceReducer";
import user from "./Reducer/userReducer";
import quote from "./Reducer/quotesReducer";
import dashboard from "./Reducer/dashboardReducer";
import feedback from "./Reducer/feedbackReducer";
import  changeStatus  from "./Reducer/changeStatusReducer";

const rootReducer = combineReducers({
    authAdmin: authAdmin,
    service: service,
    user: user,
    quote: quote,
    dashboard: dashboard,
    feedback: feedback,
    changeStatus: changeStatus
});

export default rootReducer;