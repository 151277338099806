import { Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { deleteService, getServiceListing } from '../../Redux/Action/service';
import { clearState } from '../../Redux/Reducer/serviceReducer';
import Pagination from '../Pagination/Pagination';
import Loading from '../../Lottie/Loading';
import DeleteModal from '../DeleteModal/DeleteModal';

const Page = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data, deleteError, deleteSuccess, loading, deleteLoading } = useSelector((state: RootState) => state.service);
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    const handleDelete = async () => {
        if (id) {
            await dispatch(deleteService(id));
            setShow(false);
        }
        else {
            message.error("Service does not exist!")
        }
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        setPage(page);
        setPageSize(pageSize || 10);
    };
    const handleShow = (id: string) => {
        setShow(true);
        setId(id);
    }
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        dispatch(getServiceListing({ page, pageSize }));
    }, [page, pageSize])

    useEffect(() => {
        if (deleteError) {
            message.error(deleteError);
            dispatch(clearState());
        }
        if (deleteSuccess) {
            message.success(deleteSuccess);
            dispatch(clearState());
            dispatch(getServiceListing({ page, pageSize }));
        }
    }, [dispatch, deleteError, deleteSuccess])

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Services</h2>
                    <Button onClick={() => navigate('/services/add')}>Add New</Button>
                </div>
            </div>
            {
                loading ?
                    <div>
                        <Loading />
                    </div>
                    :
                    <div className="row">

                        <div className="col-sm-12 ttable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.items?.length > 0 ?

                                            data?.items?.map((service: any, index: number) => (
                                                <tr key={service._id}>
                                                    <td onClick={() => navigate(`/services/${service._id}`)}>{index + 1}</td>
                                                    <td onClick={() => navigate(`/services/${service._id}`)}><b>{service.Title}</b></td>
                                                    <td onClick={() => navigate(`/services/${service._id}`)}>{service.Description.split(' ').slice(0, 15).join(' ') + (service.Description.split(' ').length > 15 ? '...' : '')}</td>

                                                    <td className='deleete'>
                                                        <div className='actionsss'>
                                                            <DeleteOutlined className='del' onClick={() => handleShow(service._id)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))

                                            :
                                            <tr className='noDataa'>
                                                <td colSpan={4}>No Data</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>

                        </div>

                        <div className="col-sm-12 paginatitton">
                            <Pagination
                                TotalData={data?.totalCount || 0}
                                handlePageChange={handlePageChange}
                                currentPage={page}
                            />
                        </div>
                    </div>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <DeleteModal handleClose={handleClose} handleDelete={handleDelete} loading={deleteLoading} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Page;