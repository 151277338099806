import React, { useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import { AppDispatch, RootState } from '../../Store';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../Redux/Action/authAdmin';
import { clearState } from '../../Redux/Reducer/authAdminReducer';

const NewPasswordForm = ({ handleClose }: { handleClose: () => void }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { passwordError, passwordSuccess, editLoading } = useSelector((state: RootState) => state.authAdmin);
    

    const validatePassword = (_: any, value: string) => {
        if (value && value.length < 4) {
          return Promise.reject("Password must be at least 4 characters long.");
        }
        return Promise.resolve(); 
    };
    
    const onFinish = (values: any) => {
        if (values.previousPassword !== values.newPassword) {
            dispatch(changePassword(values));
        } else {
            message.error("Old password and new password cannot be the same.");
        }
    };

    useEffect(() => {
        if (passwordError) {
          message.error(passwordError);
          dispatch(clearState());
        }
        if (passwordSuccess) {
          message.success(passwordSuccess);
          dispatch(clearState());
          handleClose();
        }
      }, [passwordError, passwordSuccess]);
    
    return (
        <div className='container-fluid servicess'>
            <Form
                layout='vertical'
                onFinish={onFinish}
            >
                <div className="row">
                    <div className="col-sm-12">
                        <Form.Item
                            label='Old Password'
                            name='previousPassword'
                            rules={[
                                {
                                  required: true,
                                  message: "Please enter old password",
                                }
                            ]}
                        >
                            <Input.Password placeholder='Old Password'/>
                        </Form.Item>

                    </div>

                    <div className="col-sm-12">
                        <Form.Item
                            label='New Password'
                            name='newPassword'
                            rules={[
                                {
                                  required: true,
                                  message: "Please enter new password",
                                },
                                { validator: validatePassword },
                            ]}
                        >
                            <Input.Password placeholder='New Password'/>
                        </Form.Item>

                    </div>

                    <div className="col-sm-12">
                        <Form.Item
                            label='Confirm New Password'
                            name='confirmPassword'
                            rules={[
                                {
                                  required: true,
                                  message: "Please confirm your Password",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue("newPassword") === value) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject("The two passwords do not match");
                                  },
                                }),
                            ]}
                        >
                            <Input.Password placeholder='Confirm New Password'/>
                        </Form.Item>
                    </div>

                    <div className="col-sm-12" style={{textAlign: 'right', paddingBottom: '40px', paddingTop: '20px'}}>
                        <Button htmlType='submit' type='primary' disabled={editLoading}>
                            Submit
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default NewPasswordForm
