import { createSlice } from "@reduxjs/toolkit";

interface userState {
    loading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    data: any;
}

const initialState: userState = {
    loading: false,
    error: null,
    deleteLoading: false,
    success: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        userListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        userListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        userListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singleUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        singleUserSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.success = action.payload.data.message;
        },
        singleUserFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteUserStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteUserSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteUserFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.deleteLoading = false;
        },
    },
});

export const {
    userListingStart,
    userListingSuccess,
    userListingFailure,
    singleUserStart,
    singleUserFailure,
    singleUserSuccess,
    deleteUserStart,
    deleteUserFailure,
    deleteUserSuccess,
    clearState,
} = userSlice.actions;

export default userSlice.reducer;