import { createSlice } from "@reduxjs/toolkit";

interface AuthAdminState {
    loading: boolean;
    error: string | null;
    editLoading: boolean;
    success: string | null;
    editError: string | null;
    editSuccess: string | null;
    passwordError: string | null;
    passwordSuccess: string | null;
    data: any;
    token: string | null;
    loginStatus: boolean | null;
}

const initialState: AuthAdminState = {
  loading: false,
  editLoading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  passwordError: null,
  passwordSuccess: null,
  data: {},
  token: null,
  loginStatus: false
};

const authAdminSlice = createSlice({
  name: "authAdmin",
  initialState,
  reducers: {
    authAdminLoginStart(state) {
      state.loading = true;
      state.error = null;
    },

    authAdminLoginSuccess(state, action) {
      state.loading = false;
      state.token = action.payload.data;
      state.success = action.payload.data.message;
    },
    authAdminLoginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getMyDetailsStart(state) {
      state.loading = true;
      state.error = null;
    },

    getMyDetailsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.loginStatus = action.payload.success
      
    },
    getMyDetailsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    changePasswordStart(state) {
      state.editLoading = true;
      state.passwordError = null;
    },

    changePasswordSuccess(state, action) {
      state.editLoading = false;
      state.passwordSuccess = action.payload.data.message;
    },
    changePasswordFailure(state, action) {
      state.editLoading = false;
      state.passwordError = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.editSuccess = null;
      state.editError = null;
      state.passwordSuccess = null;
      state.passwordError = null;
      state.editLoading = false;
    },
  },
});

export const {
  authAdminLoginStart,
  authAdminLoginSuccess,
  authAdminLoginFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  clearState,
} = authAdminSlice.actions;

export default authAdminSlice.reducer;