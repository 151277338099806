import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    dashboardListingStart,
    dashboardListingSuccess,
    dashboardListingFailure,
} from "../Reducer/dashboardReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { dashboardStats } from "../../interfaces";

export const getDashboardStats = createAsyncThunk<dashboardStats>(
    "dashboard",
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(dashboardListingStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<dashboardStats>(
                `${process.env.REACT_APP_API_URl}stats`,
                config
            );

            thunkAPI.dispatch(dashboardListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch admin details";

            thunkAPI.dispatch(dashboardListingFailure(errorMessage));
            throw error;
        }
    }
);