import { createSlice } from "@reduxjs/toolkit";

interface serviceState {
    loading: boolean;
    editLoading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    editError: string | null;
    editSuccess: string | null;
    postError: string | null;
    postSuccess: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    data: any;
}

const initialState: serviceState = {
    loading: false,
    deleteLoading: false,
    editLoading: false,
    error: null,
    success: null,
    editError: null,
    editSuccess: null,
    postError: null,
    postSuccess: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
        serviceListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        serviceListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        serviceListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singleServiceStart(state) {
            state.loading = true;
            state.error = null;
        },
        singleServiceSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.success = action.payload.data.message;
        },
        singleServiceFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        editServiceStart(state) {
            state.editLoading = true;
            state.editError = null;
        },
        editServiceSuccess(state, action) {
            state.editLoading = false;
            state.data = action.payload.data;
            state.editSuccess = action.payload.data.message;
        },
        editServiceFailure(state, action) {
            state.editLoading = false;
            state.editError = action.payload;
        },
        addServiceStart(state) {
            state.loading = true;
            state.postError = null;
        },
        addServiceSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.postSuccess = action.payload.data.message;
        },
        addServiceFailure(state, action) {
            state.loading = false;
            state.postError = action.payload;
        },
        deleteServiceStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteServiceSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteServiceFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.editSuccess = null;
            state.editError = null;
            state.postError = null;
            state.postSuccess = null;
            state.deleteError = null;
            state.deleteSuccess = null;
        },
    },
});

export const {
    serviceListingStart,
    serviceListingSuccess,
    serviceListingFailure,
    singleServiceStart,
    singleServiceFailure,
    singleServiceSuccess,
    editServiceStart,
    editServiceFailure,
    editServiceSuccess,
    addServiceStart,
    addServiceFailure,
    addServiceSuccess,
    deleteServiceStart,
    deleteServiceFailure,
    deleteServiceSuccess,
    //   getMyDetailsStart,
    //   getMyDetailsSuccess,
    //   getMyDetailsFailure,
    //   updateProfileStart,
    //   updateProfileSuccess,
    //   updateProfileFailure,
    //   changePasswordStart,
    //   changePasswordSuccess,
    //   changePasswordFailure,
    clearState,
} = serviceSlice.actions;

export default serviceSlice.reducer;