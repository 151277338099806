import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    feedbackListingStart,
    feedbackListingSuccess,
    feedbackListingFailure,
    deleteFeedbackFailure,
    deleteFeedbackStart,
    deleteFeedbackSuccess,
    getSingleFeedbackStart,
    getSingleFeedbackSuccess,
    getSingleFeedbackFailure,
} from "../Reducer/feedbackReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { feedbackResponse, getSingleFeedbackResponse, PaginationParams } from "../../interfaces";

export const getFeedbackListing = createAsyncThunk<feedbackResponse, PaginationParams>(
    "feedback",
    async ({ page, pageSize }, thunkAPI) => {
        try {
            thunkAPI.dispatch(feedbackListingStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<feedbackResponse>(
                `${process.env.REACT_APP_API_URl}feebacksListing`,
                config
            );

            thunkAPI.dispatch(feedbackListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch feedbacks";

            thunkAPI.dispatch(feedbackListingFailure(errorMessage));
            throw error;
        }
    }
);

export const deleteFeedback = createAsyncThunk(
    "feedback/deleteFeedback",
    async (id: string, thunkAPI) => {
        try {
            thunkAPI.dispatch(deleteFeedbackStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.delete(
                `${process.env.REACT_APP_API_URl}deleteFeedback/${id}`, config
            );

            thunkAPI.dispatch(deleteFeedbackSuccess(response.data));
            return response.data;
        } catch (error: any) {
            if (error) {
                const errorMessage = error?.response?.data?.message;
                thunkAPI.dispatch(deleteFeedbackFailure(errorMessage));
                return thunkAPI.rejectWithValue(errorMessage);
            }
        }
    }
);

export const getSingleFeedback = createAsyncThunk<getSingleFeedbackResponse, { id: string }, { rejectValue: string }>(
    "feedback/getSingleFeedback",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getSingleFeedbackStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<getSingleFeedbackResponse>(
                `${process.env.REACT_APP_API_URl}getSingleFeedback/${id}`,
                config
            );

            thunkAPI.dispatch(getSingleFeedbackSuccess(response?.data?.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch Feedback details";
            thunkAPI.dispatch(getSingleFeedbackFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);
