import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';

interface DeleteModalProps {
    handleClose: () => void;
    handleDelete: () => void;
    loading: boolean;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ handleClose, handleDelete, loading }) => {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 surree">
                    <p>Are you sure you want to delete ?</p>
                </div>
                <div className="col-sm-12 bttnss">
                    <Button onClick={() => handleClose()} className='cancelll'>Cancel</Button>
                    <Button onClick={() => handleDelete()} className='delBtn spinnnnerr' disabled={loading}>
                        {loading ?
                            <>
                                <Spin indicator={<LoadingOutlined spin />} />
                            </>
                            :
                            <></>
                        }
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal
