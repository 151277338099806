import React from 'react';
import logo from '../../Assets/logo.png';
import { NavLink, useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { AppDispatch, RootState } from '../../Store';
// import { getMyDetails } from '../../Redux/Action/authAdmin';
import Cookies from "js-cookie";

const Sidebar = () => {

    // const dispatch = useDispatch<AppDispatch>();
    // const { error, data, loginStatus } = useSelector((state: RootState) => state.authAdmin);

    // console.log(data, loginStatus);

    // useEffect(() => {
    //     if(!loginStatus)
    //     {   
    //         navigate('/')
    //     }
    // }, [])

    // useEffect(() => {
    //     dispatch(getMyDetails());
    // }, [dispatch])

    const navigate = useNavigate();
    const handleLogout = () => {
        navigate('/');
        Cookies.remove("token");
    };

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-sm-12 ffoortwenty">
                    <img src={logo} alt='asddd' />
                </div>
                <div className="col-sm-12 ffoorEightty" style={{paddingLeft: '0'}}>
                    <ul>
                        <li>
                            <NavLink 
                                to="/dashboard"
                                className={({ isActive }) => isActive ? "active" : ""}
                            >
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/users"
                                className={({ isActive }) => isActive ? "active" : ""}
                            >
                                Users
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/services"
                                className={({ isActive }) => isActive ? "active" : ""}
                            >
                                Services
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/quotes"
                                className={({ isActive }) => isActive ? "active" : ""}
                            >
                                Quotes
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/feedbacks"
                                className={({ isActive }) => isActive ? "active" : ""}
                            >
                                Feedbacks
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/profile"
                                className={({ isActive }) => isActive ? "active" : ""}
                            >
                                Profile
                            </NavLink>
                        </li>
                        <li onClick={handleLogout}>
                            <NavLink to="/">Logout</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
