import { message, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { deleteQuote, getQuotesListing } from '../../Redux/Action/quote';
import { clearState } from '../../Redux/Reducer/quotesReducer';
import Pagination from '../Pagination/Pagination';
import Loading from '../../Lottie/Loading';
import DeleteModal from '../DeleteModal/DeleteModal';
import StatusForm from './StatusForm';
const { Option } = Select;

const Page = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { quoteData, deleteLoading, deleteError, deleteSuccess, loading } = useSelector((state: RootState) => state.quote);
    console.log(quoteData)
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [status, setStatus] = useState<string>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const handleDelete = async () => {
        if (id) {
            console.log(id)
            await dispatch(deleteQuote(id));
            setShow(false);
        }
        else {
            message.error("Feedback does not exist!")
        }
    };

    const handleShow = (id: string) => {
        setId(id);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setId('');
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        setPage(page);
        setPageSize(pageSize || 10);
    };
    const handleChange = (id: string, value: string) => {
        setStatus(value);
        setId(id);
        handleModalOpen();
    };
    useEffect(() => {
        dispatch(getQuotesListing({ page, pageSize }));
    }, [page, pageSize])

    useEffect(() => {
        if (deleteError) {
            message.error(deleteError);
            dispatch(clearState());
        }
        if (deleteSuccess) {
            message.success(deleteSuccess);
            dispatch(clearState());
            dispatch(getQuotesListing({ page, pageSize }));
        }
    }, [dispatch, deleteError, deleteSuccess])

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Quotes</h2>
                </div>
            </div>
            {
                loading
                    ?
                    <div>
                        <Loading />
                    </div>
                    :

                    <div className="row">
                        <div className="col-sm-12 ttable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>User Name</th>
                                        <th>Email Address</th>
                                        <th>Service Name</th>
                                        <th>Location</th>
                                        <th>Quote Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        quoteData?.items?.length > 0 ? quoteData?.items?.map((qq: any, index: number) => (
                                            <tr key={qq._id} >
                                                <td onClick={() => navigate(`/quotes/${qq._id}`)}>{index + 1}</td>
                                                <td onClick={() => navigate(`/quotes/${qq._id}`)}><b>{qq?.UserRequestQuotes?.Name}</b></td>
                                                <td onClick={() => navigate(`/quotes/${qq._id}`)}><b>{qq?.UserRequestQuotes?.Email}</b></td>
                                                <td onClick={() => navigate(`/quotes/${qq._id}`)}><b>{qq?.ServiceQuoteRequest?.Title}</b></td>
                                                <td onClick={() => navigate(`/quotes/${qq._id}`)}>{qq?.Location}</td>
                                                {qq?.status == 'pending' ?
                                                    <td>
                                                        <Select
                                                            defaultValue={qq.status}
                                                            onChange={(value) => handleChange(qq._id, value)}
                                                            style={{ width: 120, textTransform: 'capitalize' }}
                                                        >
                                                            <Option value="approved" className="">Approved</Option>
                                                            <Option value="rejected" className="">Rejected</Option>
                                                        </Select>
                                                    </td>
                                                    :
                                                    qq?.status == 'approved' ?
                                                        <td className='green' style={{ textTransform: 'capitalize' }}><div></div>{qq?.status}</td>
                                                        :
                                                        <td className='red' style={{ textTransform: 'capitalize' }}><div></div>{qq?.status}</td>
                                                }


                                                <td className='deleete'>
                                                    <div className='actionsss' onClick={(e) => e.stopPropagation()}>
                                                        <DeleteOutlined className='del' onClick={() => handleShow(qq._id)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                            : <tr className='noDataa'>
                                                <td colSpan={7}>No Data</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="col-sm-12 paginatitton">
                            <Pagination
                                TotalData={quoteData?.totalCount || 0}
                                handlePageChange={handlePageChange}
                                currentPage={page}
                            />
                        </div>
                    </div>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <DeleteModal handleClose={handleClose} handleDelete={handleDelete} loading={deleteLoading} />
                </Modal.Body>
            </Modal>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Quote Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StatusForm id={id} handleClose={handleModalClose} status={status} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Page;
