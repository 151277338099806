import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

interface feedbackState {
    loading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    data: any;
}

const initialState: feedbackState = {
    loading: false,
    deleteLoading: false,
    error: null,
    success: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const feedbackSlice = createSlice({
    name: "feedback",
    initialState,
    reducers: {
        feedbackListingStart(state) {
            state.loading = true;
            state.error = null;
        },
        feedbackListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        feedbackListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteFeedbackStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteFeedbackSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteFeedbackFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        getSingleFeedbackStart(state) {
            state.loading = true;

        },
        getSingleFeedbackSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        getSingleFeedbackFailure(state, action) {
            state.loading = false;
            message.error(action.payload)
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.deleteLoading = false;
        },
    },
});

export const {
    feedbackListingStart,
    feedbackListingSuccess,
    feedbackListingFailure,
    deleteFeedbackFailure,
    deleteFeedbackStart,
    deleteFeedbackSuccess,
    getSingleFeedbackStart,
    getSingleFeedbackSuccess,
    getSingleFeedbackFailure,
    clearState,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;