import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  serviceListingStart,
  serviceListingSuccess,
  serviceListingFailure,
  singleServiceStart,
  singleServiceFailure,
  singleServiceSuccess,
  addServiceStart,
  addServiceFailure,
  addServiceSuccess,
  deleteServiceStart,
  deleteServiceFailure,
  deleteServiceSuccess,
  editServiceStart,
  editServiceSuccess,
  editServiceFailure,
} from "../Reducer/serviceReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { PaginationParams, ServiceListingResponse, SingleServiceResponse } from "../../interfaces";

export const getServiceListing = createAsyncThunk<ServiceListingResponse, PaginationParams>(
  "service",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      thunkAPI.dispatch(serviceListingStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
        params: {
          size: pageSize,
          page,
        },
      };

      const response = await axios.get<ServiceListingResponse>(
        `${process.env.REACT_APP_API_URl}serviceListing`,
        config
      );

      thunkAPI.dispatch(serviceListingSuccess(response));

      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || "Failed to fetch admin details";

      thunkAPI.dispatch(serviceListingFailure(errorMessage));
      throw error;
    }
  }
);


export const getSingleService = createAsyncThunk<SingleServiceResponse, { id: string }, { rejectValue: string }>(
  "service/getSingleService",
  async ({ id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(singleServiceStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get<SingleServiceResponse>(
        `${process.env.REACT_APP_API_URl}service/${id}`,
        config
      );

      thunkAPI.dispatch(singleServiceSuccess(response.data));
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || "Failed to fetch service details";
      thunkAPI.dispatch(singleServiceFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const addService = createAsyncThunk(
  "service/addService",
  async (formData: FormData, thunkAPI) => {
    try {
      thunkAPI.dispatch(addServiceStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URl}createService`,
        formData,
        config
      );

      thunkAPI.dispatch(addServiceSuccess(response));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to add service!";
      thunkAPI.dispatch(addServiceFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const editService = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
  "service/editService",
  async ({id, formData}, thunkAPI) => {
    try {
      thunkAPI.dispatch(editServiceStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URl}editService/${id}`,
        formData,
        config
      );

      thunkAPI.dispatch(editServiceSuccess(response));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to edit service!";
      thunkAPI.dispatch(editServiceFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const deleteService = createAsyncThunk(
  "service/deleteservice",
  async (id: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteServiceStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_API_URl}serviceDelete/${id}`, config
      );

      thunkAPI.dispatch(deleteServiceSuccess(response.data));
      return response.data;
    } catch (error: any) {
      if (error) {
        const errorMessage = error?.response?.data?.message;
        thunkAPI.dispatch(deleteServiceFailure(errorMessage));
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  }
);