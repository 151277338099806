import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, Upload, message } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { editService, getSingleService } from '../../Redux/Action/service';
import { clearState } from '../../Redux/Reducer/serviceReducer';
import Loading from '../../Lottie/Loading';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const ServiceDetail = () => {
    const dispatch = useDispatch<AppDispatch>(); 
    const { editSuccess, data, editError, loading, editLoading } = useSelector((state: RootState) => state.service);
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [imageIdsToDelete, setImageIdsToDelete] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            dispatch(getSingleService({ id }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (data && data.ServiceImages) {
            const updatedFileList: UploadFile[] = data.ServiceImages.map((simage: { _id: string, Image: string }) => ({
                uid: simage._id,
                status: 'done',
                url: simage.Image,
            }));

            setFileList(updatedFileList);
        } else {
            setFileList([]);
        }
    }, [data]);
        
    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        const removedFiles = fileList.filter(file => !newFileList.find(newFile => newFile.uid === file.uid));
        
        removedFiles.forEach(file => {
            if (file.uid) {
                setImageIdsToDelete(prev => [...prev, file.uid]);
            }
        });

        setFileList(newFileList);
    };

    useEffect(() => {
        if(editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if(editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            navigate('/services');
        }
    }, [editSuccess, editError]);
    
    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Title', values.Title);
        formData.append('Description', values.Description);
        
        imageIdsToDelete.forEach(ids => {
            formData.append('imageIdsToDelete', ids);
        });

        fileList.forEach(file => {
            if (file.originFileObj) {
                formData.append('images', file.originFileObj);
            }
        });

        if(id) {
            dispatch(editService({id, formData}));
        }
    };    
    
    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as FileType);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const beforeUpload = (file: UploadFile) => {
      const size = file.size  ? file.size :null
      let isLt2M
        if(size){
             isLt2M = size / 1024 / 1024 < 5;
        }
        if (!isLt2M) {
            message.error('Image must be smaller than 5MB!');
            return Upload.LIST_IGNORE;
        }
        return isLt2M;
    };

    useEffect(() => {
        if(data) {
            form.setFieldsValue({
                Title: data?.Title,
                Description: data?.Description,
            });
        }
    }, [data]);
    
    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Edit Service</h2>
                </div>

                <div className="col-sm-12" style={{paddingTop: '30px'}}>
                    {
                        loading ? 
                        <div>
                            <Loading />
                        </div> :
                        <Form 
                            layout='vertical' 
                            onFinish={onFinish} 
                            initialValues={data} 
                            form={form}
                        >
                            <div className="row">
                                <div className="col-sm-12">
                                    <Form.Item
                                        label="Title"
                                        name="Title"
                                        rules={[{ required: true, message: 'Please input the Title!' }]} 
                                    >
                                        <Input placeholder='Title' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12">
                                    <Form.Item
                                        label="Description"
                                        name="Description"
                                        rules={[{ required: true, message: 'Please input the description!' }]} 
                                    >
                                        <Input.TextArea placeholder='Description' rows={5} />
                                    </Form.Item>
                                </div>

                                <ImgCrop rotationSlider>
      <Upload
    action="/upload.do" 
    listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        beforeUpload={beforeUpload}

        maxCount={5}
        onPreview={onPreview}
      >
        {fileList.length < 5 && '+ Upload'}
      </Upload>
    </ImgCrop>

                                <div className="col-sm-12" style={{textAlign: 'right', marginTop: '20px'}} >
                                    <Button type="primary" htmlType="submit" disabled={editLoading} className='spinnnnerr'>
                                    {editLoading ? 
                                        <>
                                            <Spin indicator={<LoadingOutlined spin />}  /> 
                                        </>
                                        : 
                                        <></> 
                                        }
                                        
                                        Submit</Button>
                                </div>
                            </div>
                        </Form>
                    }
                </div>
            </div>
        </div>
    );
};

export default ServiceDetail;
