import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Image } from 'antd';
import { AppDispatch, RootState } from '../../Store';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleUser } from '../../Redux/Action/user';
import moment from 'moment';
import Loading from '../../Lottie/Loading';
import sample from '../../Assets/avatar.jpg'

const UserDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data, loading } = useSelector((state: RootState) => state.user);
    const { id } = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            dispatch(getSingleUser({ id }));
        }
    }, [dispatch, id]);


    const onFinish = (values: any) => {
        console.log('Updated service details:', values);
    };

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                Email: data?.Email,
                Name: data?.Name,
                Phone: data?.Phone,
                Gender: data?.Gender,
                DOB: data?.DOB ? moment(data?.DOB).format("MMM DD, YYYY") : ''
            })
        }
    }, [data])


    return (
        <div className='container-fluid servicess userdetailll'>
            {
                loading ?
                    <div><Loading /></div>
                    :
                    <div className="row">
                        <div className="col-sm-12 addnew">
                            <h2>User Detail</h2>
                        </div>

                        {data?.ProfilePic ?
                            <div className="col-sm-12" style={{ padding: '30px 15px 0' }}>
                                <Image src={data?.ProfilePic} style={{ width: '300px', height: '300px', borderRadius: '50%' }} />
                            </div>
                            :
                            <div className="col-sm-12" style={{ padding: '30px 15px 0' }}>
                                <img src={sample} style={{ width: '300px', height: '300px', borderRadius: '50%' }} alt='profile'/>
                            </div>
                        }


                        <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                            <Form layout='vertical' onFinish={onFinish} form={form} initialValues={data}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Name"
                                            name="Name"
                                        >
                                            <Input readOnly placeholder='Name' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Email"
                                            name="Email"
                                        >
                                            <Input readOnly placeholder='Email' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Phone"
                                            name="Phone"
                                        >
                                            <Input readOnly placeholder='Phone' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Gender"
                                            name="Gender"
                                        >
                                            <Input readOnly placeholder='Gender' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="DOB"
                                            name="DOB"
                                        >
                                            <Input readOnly placeholder='DOB' />
                                        </Form.Item>
                                    </div>



                                    {/* <div className="col-sm-12" style={{textAlign: 'right', marginTop: '20px'}} >
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                </div> */}
                                </div>
                            </Form>
                        </div>
                    </div>
            }
        </div>
    );
};

export default UserDetail;
