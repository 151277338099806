import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Upload, message, Modal } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { addService } from '../../Redux/Action/service';
import { clearState } from '../../Redux/Reducer/serviceReducer';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const AddServicePage = () => {
    const dispatch = useDispatch<AppDispatch>(); 
    const { postError, postSuccess, loading } = useSelector((state: RootState) => state.service);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        if (postError) {
          message.error(postError);
          dispatch(clearState());
        }
        if (postSuccess) {
          message.success(postSuccess);
          dispatch(clearState());
          navigate('/services')
        }
      }, [postSuccess, postError]);

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    
    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Title', values.Title);
        formData.append('Description', values.Description);

        fileList.forEach((file) => {
            formData.append('images', file.originFileObj as File);
        });

        dispatch(addService(formData));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as File);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        setPreviewImage(src);
        setPreviewVisible(true); // Show the modal
    };

    const handleCancel = () => setPreviewVisible(false); // Close the modal

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Add New Service</h2>
                </div>

                <div className="col-sm-12" style={{paddingTop: '30px'}}>
                    <Form 
                        form={form}
                        layout='vertical' 
                        onFinish={onFinish} 
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <Form.Item
                                    label="Title"
                                    name="Title"
                                    rules={[{ required: true, message: 'Please input the Title!' }]} 
                                >
                                    <Input placeholder='Title' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-12">
                                <Form.Item
                                    label="Description"
                                    name="Description"
                                    rules={[{ required: true, message: 'Please input the description!' }]} 
                                >
                                    <Input.TextArea placeholder='Description' rows={5} />
                                </Form.Item>
                            </div>

                            <div className="col-sm-12">
                                <Form.Item name="images">
                                    <ImgCrop rotationSlider>
                                        <Upload
                                            name='file'
                                            maxCount={5}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onChange={onChange}
                                            onPreview={onPreview}
                                        >
                                            {fileList.length < 5 && '+ Upload'}
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </div>

                            <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                    {loading ? 
                                        <Spin indicator={<LoadingOutlined spin />} /> 
                                        : 
                                        'Submit'
                                    }
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default AddServicePage;
