import { createSlice } from "@reduxjs/toolkit";

interface changeStatusState {
    loading: boolean;
    error: string | null;
    success: string | null;
}

const initialState: changeStatusState = {
    loading: false,
    error: null,
    success: null,    
};

const changeStatusSlice = createSlice({
    name: "changestatus",
    initialState,
    reducers: {
    
        
        changeStatusStart(state) {
            state.loading = true;
            state.error = null;
        },
        changeStatusSuccess(state, action) {
            state.loading = false;
            console.log(action.payload, "<=====payload")
            state.success = action.payload.data.message;
        },
        changeStatusFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
      
     
        clearState: (state) => {
            state.error = null;
            state.success = null;
    
        },
    },
});

export const {
    changeStatusStart,
    changeStatusSuccess,
    changeStatusFailure,
    clearState,
} = changeStatusSlice.actions;

export default changeStatusSlice.reducer;