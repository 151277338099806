import React from "react";
import "./App.css";
import { BrowserRouter, useRoutes } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Services from "./Pages/Services";
import ServiceDetail from "./Pages/ServiceDetail";
import AddService from "./Pages/AddService";
import Quotes from "./Pages/Quotes";
import ViewQuote from "./Pages/ViewQuote";
import Users from "./Pages/Users";
import UsersDetails from "./Pages/UsersDetails";
import Feedback from "./Pages/Feedback";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./Store";
import ProtectedRoute from "./Components/ProtectedRoute";
import FeedBackDetail from "./Pages/FeedBackDetail";
import Profile from "./Pages/Profile";
// import AddService from "./Pages/AddService"; // Import the new AddService component



function App() {
  const token = Cookies.get("token");
  const dispatch = useDispatch<AppDispatch>();
  const { error, data, loginStatus } = useSelector((state: RootState) => state.authAdmin);
  let element = useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "dashboard",
      element: (
        <ProtectedRoute token={token}>
      <Dashboard />
      </ProtectedRoute>
      ),
    },
    {
      path: "profile",
      element:(
        <ProtectedRoute token={token}>
        <Profile />
        </ProtectedRoute>
        ),
    },
    {
      path: "users",
      element:  (       <ProtectedRoute token={token}><Users />
      
      </ProtectedRoute>),
    },
    {
      path: "users/:id",
      element:(
        <ProtectedRoute token={token}>
        <UsersDetails />
        </ProtectedRoute>
        ),
    },
    {
      path: "feedbacks",
      element:(
        <ProtectedRoute token={token}>       
         <Feedback />
        </ProtectedRoute>

        ),
    },
    {
      path: "quotes",
      element: (
      
        <ProtectedRoute token={token}>
      <Quotes />
      </ProtectedRoute>
      ),
    },
    {
      path: "services",
      element: (
        <ProtectedRoute token={token}>
          <Services />
        </ProtectedRoute>
      ),
    },    
    {
      path: "services/:id",
      element:(
        <ProtectedRoute token={token}>
        <ServiceDetail />
        </ProtectedRoute>),
    },
    {
      path: "quotes/:id",
      element: (
      
        <ProtectedRoute token={token}>
      <ViewQuote />
      </ProtectedRoute>
      ),
    },
    {
      path: "services/add",
      element: (
        <ProtectedRoute token={token}>

      <AddService />
      
      </ProtectedRoute>
      ),
    },
    {
      path: "feedbacks/:id",
      element:(        <ProtectedRoute token={token}>
         <FeedBackDetail />
         </ProtectedRoute>
         ),
    },
    
  ]);

  return element;
}

export default App;
