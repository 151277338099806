import React, { useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { clearState } from '../../Redux/Reducer/changeStatusReducer';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { changeQuoteStatus } from '../../Redux/Action/quoteStatus';

interface StatusModalProps {
    handleClose: () => void;
    id?: string;
    status?: string;
}

const StatusForm: React.FC<StatusModalProps> = ({ handleClose, status, id }) => {
    
    const dispatch = useDispatch<AppDispatch>();
    const { success, loading } = useSelector((state: RootState) => state.changeStatus);
    const [form] = Form.useForm();


    const calculateTotalPrice = () => {
        const basePrice = parseFloat(form.getFieldValue("BasePrice")) || 0;
        const additionalFees = parseFloat(form.getFieldValue("AdditionalFees")) || 0;
        const discountApplied = parseFloat(form.getFieldValue("DiscountApplied")) || 0;
        const tax = parseFloat(form.getFieldValue("Tax")) || 0;
    
        const subtotal = basePrice + additionalFees - discountApplied;
        const totalPrice = subtotal + (subtotal * tax / 100);
    
        form.setFieldsValue({ TotalPrice: totalPrice.toFixed(2) });
    };



    useEffect(() => {

        if (success) {
            message.success(success);
            dispatch(clearState());
            handleClose()
        }
    }, [success]);

    const onFinish = (values: any) => {


        values.status = status
        if (id) {
            dispatch(changeQuoteStatus({ id, values }));
        } else {
            console.error('ID is required but was undefined');
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2> {status == "approved" ? "Approval" : "Rejection"} Request</h2>
                </div>

                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <div className="row">
                {status === "rejected" ? (
                    <div className="col-sm-12">
                        <Form.Item
                            label="Reason"
                            name="Reason"
                            rules={[{ required: true, message: 'Please input the Reason!' }]}
                        >
                            <Input.TextArea placeholder='Reason' rows={5} />
                        </Form.Item>
                    </div>
                ) : (
                    <>
                        <div className="col-sm-12">
                            <Form.Item
                                label="Base Price"
                                name="BasePrice"
                                rules={[{ required: true, message: 'Please input the Base Price!' }]}
                            >
                                <Input
                                    type='number'
                                    placeholder='Base Price'
                                    onChange={calculateTotalPrice}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                label="Additional Fees"
                                name="AdditionalFees"
                            >
                                <Input
                                    type='number'
                                    placeholder='Additional Fees'
                                    onChange={calculateTotalPrice}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                label="Discount Applied"
                                name="DiscountApplied"
                            >
                                <Input
                                    type='number'
                                    placeholder='Discount Applied'
                                    onChange={calculateTotalPrice}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                label="Tax (%)"
                                name="Tax"
                                rules={[{ required: true, message: 'Please input the Tax!' }]}
                            >
                                <Input
                                    type='number'
                                    placeholder='Tax'
                                    onChange={calculateTotalPrice}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                label="Total Price"
                                name="TotalPrice"
                                rules={[{ required: true, message: 'Please input the Total Price!' }]}
                            >
                                <Input
                                    type='number'
                                    placeholder='Total Price'
                                    readOnly
                                />
                            </Form.Item>
                        </div>
                    </>
                )}

                <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                    <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                        {loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
                        Submit
                    </Button>
                </div>
            </div>
        </Form>
                </div>
            </div>
        </div>
    );
};

export default StatusForm;
